<template>
  <div>
    <b-modal
      id="add-new-birth-sidebar"
      centered
      :visible="isAddNewBirthSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('birthAdd')"
      @ok="onSubmit(blankBirth)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-birth-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- kulakno -->
          <validation-provider
            #default="validationContext"
            name="kulakno"
            rules="required"
          >
            <b-form-group :label="$t('earingno')" label-for="kulakno">
              <b-form-input
                id="kulakno"
                v-model="blankBirth.kulakno"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- dogumtarihi -->
          <validation-provider
            #default="validationContext"
            name="dogumtarihi"
            rules="required"
          >
            <b-form-group :label="$t('birthDateTime')" label-for="dogumtarihi">
              <b-form-datepicker
                v-model="blankBirth.dogumtarihi"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- dogumagirligi -->
          <validation-provider
            #default="validationContext"
            name="dogumagirligi"
            rules="required"
          >
            <b-form-group :label="$t('birthWeight')" label-for="dogumagirligi">
              <b-form-input
                id="dogumagirligi"
                v-model="blankBirth.dogumagirligi"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- grupid -->
          <validation-provider
            #default="validationContext"
            name="grupid"
            rules="required"
          >
            <b-form-group :label="$t('group')" label-for="grupid">
              <v-select
                id="grupid"
                v-model="blankBirth.grupid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- dogumsekli -->
          <validation-provider
            #default="validationContext"
            name="dogumsekli"
            rules="required"
          >
            <b-form-group :label="$t('birthType')" label-for="dogumsekli">
              <v-select
                id="dogumsekli"
                v-model="blankBirth.dogumsekli"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="birthTypeOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- birthproblemid -->
          <validation-provider
            #default="validationContext"
            name="birthproblemid"
          >
            <b-form-group
              :label="$t('birthProblem')"
              label-for="birthproblemid"
            >
              <v-select
                id="birthproblemid"
                v-model="blankBirth.birthproblemid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="birthProblemOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- ad -->
          <validation-provider #default="validationContext" name="ad">
            <b-form-group :label="$t('name')" label-for="ad">
              <b-form-input
                id="ad"
                v-model="blankBirth.ad"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- personel -->
          <validation-provider #default="validationContext" name="personel">
            <b-form-group :label="$t('examiner')" label-for="personel">
              <b-form-input
                id="personel"
                v-model="blankBirth.personel"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- gender -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="gender"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="$t('gender')"
              label-for="gender"
            >
              <v-select
                id="gender"
                v-model="blankBirth.cinsiyet"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genderOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- digerproblemler -->
          <validation-provider
            #default="validationContext"
            name="digerproblemler"
          >
            <b-form-group
              :label="$t('otherProblems')"
              label-for="digerproblemler"
            >
              <b-form-input
                id="digerproblemler"
                v-model="blankBirth.digerproblemler"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import vSelect from "vue-select";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewBirthSidebarActive",
    event: "update:is-add-new-birth-sidebar-active",
  },
  props: {
    isAddNewBirthSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      breedOptions: [],
      groupOptions: [],
      birthTypeOptions: [],
      genderOptions: [
        { label: "Dişi", value: 1 },
        { label: "Erkek", value: 2 },
      ],
      birthProblemOptions: [],
    };
  },
  async created() {
    const [breeds, groups, birthProblems, birthTypes] =
      await Promise.allSettled([
        this.$http.get("/Animals/GetAnimalBreeds"),
        this.$store.dispatch(
          "animalsModule/fetchGroups",
          this.$store.state.app.selectedPlantId
        ),
        this.$store.dispatch(
          "definitionsModule/fetchBirthProblems",
          this.$store.state.app.selectedPlantId
        ),
        this.$store.dispatch(
          "definitionsModule/fetchBirthTypes",
          this.$store.state.app.selectedPlantId
        ),
      ]);

    if (breeds.status === "fulfilled")
      this.breeds = breeds.value.data.map((x) => ({
        label: x.groupname,
        value: x.id,
      }));
    if (groups.status === "fulfilled")
      this.groupOptions = groups.value.map((x) => ({
        label: x.groupname,
        value: x.id,
      }));
    if (birthProblems.status === "fulfilled")
      this.birthProblemOptions = birthProblems.value.map((x) => ({
        label: x.definition,
        value: x.problemid,
      }));
    if (birthTypes.status === "fulfilled")
      this.birthTypeOptions = birthTypes.value.map((x) => ({
        label: x.birthtypename,
        value: x.birthtypeid,
      }));
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const blankBirthData = {
      id: 0,
      companyid: getUserData().companyid,
      anaid: props.selectedAnimal.id,
      kulakno: "",
      ad: "",
      dogumtarihi: dayJs().format(),
      personel: "",
      irkid: 1,
      dogumsekli: 0,
      cinsiyet: 1,
      dogumagirligi: 0,
      grupid: 1,
      birthproblemid: 0,
      digerproblemler: "",
    };

    const blankBirth = ref(JSON.parse(JSON.stringify(blankBirthData)));
    const resetblankBirth = () => {
      blankBirth.value = JSON.parse(JSON.stringify(blankBirthData));
    };

    const onSubmit = (birthData) => {
      birthData.sourceid = 2;
      birthData.birthtypeid = birthData.dogumsekli;
      store.dispatch("animalsModule/addAnimalBirth", birthData).then((res) => {
        if (res.statusdata == "-2") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAlreadyAdded"),
              icon: "AlertCircleIcon",
              variant: "alert",
            },
          });
        } else if (res.statusdata == "1") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });

          emit("refetch-data");
          store.dispatch("animalsModule/fetchAnimals");
          emit("update:is-add-new-birth-sidebar-active", false);
        } else {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalBirthNotAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankBirth);
    return {
      blankBirth,
      blankBirthData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-birth-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
