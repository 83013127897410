import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useBirthsList() {
  // Use toast
  const toast = useToast();

  const refBirthListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "earingno",
      label: VueI18n.t("earingno"),
      sortable: false,
    },
    {
      key: "birthdate",
      label: VueI18n.t("birthDateTime"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY dddd")}`,
    },
    {
      key: "momid",
      label: VueI18n.t("mother"),
      sortable: false,
    },
    {
      key: "birthproblemid",
      label: VueI18n.t("birthProblem"),
      sortable: true,
    },

    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalBirths = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allBirths = ref([]);
  const filteredBirths = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredBirths.value.length;
    const to = perPage.value * currentPage.value < localItemsCount;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: to
        ? perPage.value * (currentPage.value - 1) + perPage.value
        : localItemsCount,
      of: allBirths.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value) {
      filteredBirths.value = allBirths.value.filter((x) =>
        x.birthname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    } else {
      filteredBirths.value = allBirths.value;
    }
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchBirths = async (ctx) => {
    var birth = await store
      .dispatch("animalsModule/fetchAnimalBirth", {
        animalid: ctx,
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("births") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allBirths.value = birth;
    filteredBirths.value = [...birth];
    refetchData();

    return birth;
  };

  const deleteBirth = (ctx) => {
    store
      .dispatch("animalsModule/deleteAnimalBirth", ctx.birthid)
      .then((response) => {
        fetchBirths(ctx.animalid);
        store.dispatch("animalsModule/fetchAnimals");
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("births") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("births") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  return {
    fetchBirths,
    tableColumns,
    perPage,
    currentPage,
    totalBirths,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBirthListTable,
    deleteBirth,
    filteredBirths,
    allBirths,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
