<template>
  <div>
    <b-modal
      id="edit-birth-sidebar"
      centered
      :visible="isEditBirthSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :title="$t('editAnimalBirth')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankBirth)"
      @hidden="formValidation(resetblankbirth).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="formValidation(resetblankbirth).resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />

          <!-- kulakno -->
          <validation-provider
            #default="validationContext"
            name="kulakno"
            rules="required"
          >
            <b-form-group :label="$t('earingno')" label-for="kulakno">
              <b-form-input
                id="kulakno"
                v-model="blankBirth.kulakno"
                autofocus
                :state="
                  formValidation(resetblankbirth).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- dogumtarihi -->
          <validation-provider
            #default="validationContext"
            name="dogumtarihi"
            rules="required"
          >
            <b-form-group :label="$t('birthDateTime')" label-for="dogumtarihi">
              <b-form-datepicker
                v-model="blankBirth.dogumtarihi"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- dogumagirligi -->
          <validation-provider
            #default="validationContext"
            name="dogumagirligi"
            rules="required"
          >
            <b-form-group :label="$t('birthWeight')" label-for="dogumagirligi">
              <b-form-input
                id="dogumagirligi"
                v-model="blankBirth.dogumagirligi"
                autofocus
                :state="
                  formValidation(resetblankbirth).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- grupid -->
          <validation-provider
            #default="validationContext"
            name="grupid"
            rules="required"
          >
            <b-form-group :label="$t('group')" label-for="grupid">
              <v-select
                id="grupid"
                v-model="blankBirth.grupid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- dogumsekli -->
          <validation-provider
            #default="validationContext"
            name="dogumsekli"
            rules="required"
          >
            <b-form-group :label="$t('birthType')" label-for="dogumsekli">
              <v-select
                id="dogumsekli"
                v-model="blankBirth.dogumsekli"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="birthTypeOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- birthproblemid -->
          <validation-provider
            #default="validationContext"
            name="birthproblemid"
          >
            <b-form-group
              :label="$t('birthProblem')"
              label-for="birthproblemid"
            >
              <v-select
                id="birthproblemid"
                v-model="blankBirth.birthproblemid"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="birthProblemOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- ad -->
          <validation-provider #default="validationContext" name="ad">
            <b-form-group :label="$t('name')" label-for="ad">
              <b-form-input
                id="ad"
                v-model="blankBirth.ad"
                autofocus
                :state="
                  formValidation(resetblankbirth).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- personel -->
          <validation-provider #default="validationContext" name="personel">
            <b-form-group :label="$t('examiner')" label-for="personel">
              <b-form-input
                id="personel"
                v-model="blankBirth.personel"
                autofocus
                :state="
                  formValidation(resetblankbirth).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- gender -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="gender"
          >
            <b-form-group
              :state="
                formValidation(resetblankbirth).getValidationState(
                  validationContext
                )
              "
              :label="$t('gender')"
              label-for="gender"
            >
              <v-select
                id="gender"
                v-model="blankBirth.cinsiyet"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genderOptions"
                class="w-100"
                :reduce="(val) => val.value"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- digerproblemler -->
          <validation-provider
            #default="validationContext"
            name="digerproblemler"
          >
            <b-form-group
              :label="$t('otherProblems')"
              label-for="digerproblemler"
            >
              <b-form-input
                id="digerproblemler"
                v-model="blankBirth.digerproblemler"
                autofocus
                :state="
                  formValidation(resetblankbirth).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditBirthSidebarActive",
    event: "update:is-edit-birth-sidebar-active",
  },
  props: {
    isEditBirthSidebarActive: {
      type: Boolean,
      required: true,
    },
    birth: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankBirth: { ...this.birth },
      formValidation: formValidation,

      breedOptions: [],
      groupOptions: [],
      birthTypeOptions: [],
      genderOptions: [
        { label: "Dişi", value: 1 },
        { label: "Erkek", value: 2 },
      ],
      birthProblemOptions: [],
    };
  },
  mounted() {
    this.resetblankbirth();
  },
  async created() {
    const [breeds, groups, birthProblems, birthTypes] =
      await Promise.allSettled([
        this.$http.get("/Animals/GetAnimalBreeds"),
        this.$store.dispatch(
          "animalsModule/fetchGroups",
          this.$store.state.app.selectedPlantId
        ),
        this.$store.dispatch(
          "definitionsModule/fetchBirthProblems",
          this.$store.state.app.selectedPlantId
        ),
        this.$store.dispatch(
          "definitionsModule/fetchBirthTypes",
          this.$store.state.app.selectedPlantId
        ),
      ]);

    if (breeds.status === "fulfilled")
      this.breeds = breeds.value.data.map((x) => ({
        label: x.name,
        value: x.id,
      }));
    if (groups.status === "fulfilled")
      this.groupOptions = groups.value.map((x) => ({
        label: x.groupname,
        value: x.id,
      }));
    if (birthProblems.status === "fulfilled")
      this.birthProblemOptions = birthProblems.value.map((x) => ({
        label: x.definition,
        value: x.problemid,
      }));
    if (birthTypes.status === "fulfilled")
      this.birthTypeOptions = birthTypes.value.map((x) => ({
        label: x.birthtypename,
        value: x.birthtypeid,
      }));
  },
  methods: {
    async changed(val) {
      this.resetblankbirth();
      this.$emit("update:is-edit-birth-sidebar-active", val);
    },
    onSubmit(birth) {
      birth.animalid = birth.calfid;
      birth.earingno = birth.kulakno;
      birth.birthtypeid = birth.birthTypeid;
      store.dispatch("animalsModule/updateAnimalBirth", birth).then(() => {
        this.$emit("refetch-data");
        store.dispatch("animalsModule/fetchAnimals");
        this.$emit("update:is-edit-birth-sidebar-active", false);
      });
    },
    async resetblankbirth() {
      this.blankBirth = {
        id: this.birth.id,
        companyid: getUserData().companyid,
        anaid: this.birth.momid,
        kulakno: this.birth.earingno,
        ad: this.birth.animalName,
        dogumtarihi: dayjs(this.birth.birthdate).format(),
        personel: this.birth.personel,
        irkid: this.birth.breedid,
        dogumsekli: this.birth.birthTypeid,
        cinsiyet: this.birth.genderid,
        dogumagirligi: this.birth.birthweight,
        grupid: this.birth.groupid,
        birthproblemid: 0,
        digerproblemler: this.birth.otherproblems,

        ...this.birth,
        birthid: this.birth.id,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-birth-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
